import type { Options } from '#netzo/shared/types/core'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultMessage = (data: Partial<Message>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    status: 'pending',
  }, data)
}

export const optionsMessages = {
  tableName: optionsShared.tableName,
  status: [
    { value: 'pending', label: 'Pendiente', color: 'gray', icon: 'i-mdi-clock' },
    { value: 'sent', label: 'Enviado', color: 'green', icon: 'i-mdi-check-circle' },
    { value: 'error', label: 'Error', color: 'red', icon: 'i-mdi-alert' },
  ],
  templateId: [
    {
      value: 'netzo_portals_serviceorders_installation_schedule_request',
      label: 'Solicitud de programación de instalación',
      description: 'Solicita al cliente la programación de la instalación.',
      icon: 'i-mdi-calendar-clock',
      color: 'blue',
      button: (data: Message) => ({
        icon: 'i-mdi-open-in-new',
        label: 'Confirmar/reagendar cita',
        href: `/portals/installationorders/${data.installationorderId}`,
        target: '_blank',
      }),
      template: `🛠 *Instalación Programada*

Estimado cliente,

la instalación para su orden <strong>{{name}}</strong> ha sido agendada con los siguientes detalles:

📍 Dirección: <strong>{{address}}</strong>
📅 Fecha: <strong>{{date}}</strong>
⏰ Hora estimada de llegada: <strong>{{time}}</strong>

Le pedimos confirmar su cita o reagendar en un horario alternativo.

¡Gracias por su colaboración!

Equipo LaTinta
`,
    },
    {
      value: 'netzo_portals_serviceorders_installation_schedule_reminder',
      label: 'Recordatorio de instalación',
      description: 'Recuerda al cliente la fecha y hora de la instalación.',
      icon: 'i-mdi-calendar-clock',
      color: 'gray',
      template: `*🔔 Recordatorio de Instalación*
Estimado cliente,

Le recordamos que la instalación de su orden <strong>{{name}}</strong> está programada para 📅 <strong>{{date}}</strong>:

Llegaremos a sus instalaciones en:📍<strong>{{address}}</strong> alrededor de las ⏰ <strong>{{time}}</strong>.

Por favor, asegúrese de tener el área de instalación despejada y esté atento a su WhatsApp. Al finalizar los trabajos, deberá validar su conformidad.

¡Gracias por su cooperación!
Equipo LaTinta`,
    },
    {
      value: 'netzo_portals_serviceorders_installation_approval_request',
      label: 'Solicitud de verificación de instalación',
      description: 'Solicita al cliente la verificación de la instalación.',
      icon: 'i-mdi-help-circle',
      color: 'yellow',
      button: (data: Message) => ({
        icon: 'i-mdi-open-in-new',
        label: 'Verificar instalación',
        href: `/portals/installationorders/${data.installationorderId}`,
        target: '_blank',
      }),
      template: `📩 *Solicitud de Aprobación*

Estimado cliente,

🏁 Hemos finalizado la instalación de su orden <strong>{{name}}</strong>.

Le solicitamos amablemente revisar el trabajo realizado y confirmar su aprobación navegando al enlace.

Equipo La Tinta
`,
    },
    {
      value: 'netzo_portals_serviceorders_installation_approval_approved',
      label: 'Aprobación de instalación confirmada',
      description: 'Confirma al cliente la aprobación de la instalación.',
      icon: 'i-mdi-check-circle',
      color: 'green',
      template: `🙏 *¡Gracias y hasta pronto!*

Estimado cliente,

Le confirmamos la recepcion de su firma de conformidad, en breve recibira una copia en su correo.

¡Nos alegra saber que quedo satisfecho con nuestro trabajo! Esperamos volver a hacer negocios con usted muy pronto.

Atentamente,
Equipo LaTinta
`,
    },
    {
      value: 'netzo_portals_serviceorders_installation_approval_rejected',
      label: 'Aprobación de instalación declinada',
      description: 'Informa al cliente que la aprobación de la instalación fue declinada.',
      icon: 'i-mdi-close-circle',
      color: 'red',
      template: `😔 *Comprometidos con su satisfacción*

Estimado cliente,

Sentimos que la instalación de su orden <strong>{{name}}</strong> no haya cumplido con sus expectativas.

Pronto nuestro equipo le contactará para resolverlo.

Gracias por su comprensión,
Equipo LaTinta
`,
    },
  ],
} satisfies Options

export const optionsMessagesMap = {
  Estado: optionsMessages.status,
  Plantilla: optionsMessages.templateId,
}

export const relationsMessages: Record<string, boolean> = {
  contact: true,
  installationorder: true,
}

export type MessageWithRelations = Message & {}
