import type { Productionorderitem } from '#netzo/shared/types/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultProductionorderitemData = (data: Partial<Productionorderitem['data']>) => {
  return merge({
    approvedAt: null,
    approvedBy: null,
    items: [
      { resolved: false, label: 'Cantidad' },
      { resolved: false, label: 'Bandeo' },
      { resolved: false, label: 'Manchado' },
      { resolved: false, label: 'Traslape' },
      { resolved: false, label: 'Rebase' },
      { resolved: false, label: 'Corte' },
      { resolved: false, label: 'Acabados' },
    ],
  }, data)
}
