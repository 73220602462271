
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as auditlogsIneyrKQpAMMeta } from "/Users/grk/repos/netzo/pages/admin/auditlogs.vue?macro=true";
import { default as apikeysYENqiTxe3zMeta } from "/Users/grk/repos/netzo/pages/admin/auth/apikeys.vue?macro=true";
import { default as users8wJ7Dq3IFYMeta } from "/Users/grk/repos/netzo/pages/admin/auth/users.vue?macro=true";
import { default as issuesjOJdG91onYMeta } from "/Users/grk/repos/netzo/pages/admin/issues.vue?macro=true";
import { default as assistanteDIcQfvTccMeta } from "/Users/grk/repos/netzo/pages/assistant.vue?macro=true";
import { default as forgot_45passwordeiGEnjblaJMeta } from "/Users/grk/repos/netzo/pages/auth/forgot-password.vue?macro=true";
import { default as loginmDRjXFrBGjMeta } from "/Users/grk/repos/netzo/pages/auth/login.vue?macro=true";
import { default as registerNznzSu1ZFRMeta } from "/Users/grk/repos/netzo/pages/auth/register.vue?macro=true";
import { default as reset_45passwordDtLuaTpmg5Meta } from "/Users/grk/repos/netzo/pages/auth/reset-password.vue?macro=true";
import { default as verify_45otpeLWG3WxB6pMeta } from "/Users/grk/repos/netzo/pages/auth/verify-otp.vue?macro=true";
import { default as detailsxmPuimBZBbMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id]/details.vue?macro=true";
import { default as filesnaU9cQuXXlMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id]/files.vue?macro=true";
import { default as indexmIxyqMxI7fMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id]/index.vue?macro=true";
import { default as notesezbW3oQBjCMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id]/notes.vue?macro=true";
import { default as taskskhe9R2ZiNlMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id]/tasks.vue?macro=true";
import { default as _91id_93doKB6VYy3AMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/[id].vue?macro=true";
import { default as index6K4bH83ivIMeta } from "/Users/grk/repos/netzo/pages/catalogs/applications/index.vue?macro=true";
import { default as detailshy49lKJpPEMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/details.vue?macro=true";
import { default as filesDyy1aDnG1GMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/files.vue?macro=true";
import { default as indexV3P2EpPUCdMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/index.vue?macro=true";
import { default as notesZlaSv9YrPLMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/notes.vue?macro=true";
import { default as productstgM4kacpA2Meta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/products.vue?macro=true";
import { default as servicesNlaZ3DncrVMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/services.vue?macro=true";
import { default as tasksPfTZMg9AzFMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id]/tasks.vue?macro=true";
import { default as _91id_93JhqMMVQVb1Meta } from "/Users/grk/repos/netzo/pages/catalogs/categories/[id].vue?macro=true";
import { default as indexjeIn6Pg4oeMeta } from "/Users/grk/repos/netzo/pages/catalogs/categories/index.vue?macro=true";
import { default as detailsDdeymNZqVAMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id]/details.vue?macro=true";
import { default as files6YfAFmFSlNMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id]/files.vue?macro=true";
import { default as indexmNWcre7KPBMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id]/index.vue?macro=true";
import { default as notesATBhWoQXelMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id]/notes.vue?macro=true";
import { default as tasksT9uqxLQ3e3Meta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id]/tasks.vue?macro=true";
import { default as _91id_93imUzE0qlmFMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/[id].vue?macro=true";
import { default as indexnVbgFoIVFHMeta } from "/Users/grk/repos/netzo/pages/catalogs/industries/index.vue?macro=true";
import { default as detailsjNe323qjD4Meta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/details.vue?macro=true";
import { default as files10qhXNr5tYMeta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/files.vue?macro=true";
import { default as indexqqFdpoSssXMeta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/index.vue?macro=true";
import { default as itemsJ3lqq7qY4aMeta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/items.vue?macro=true";
import { default as notesEGsyeP8JKkMeta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/notes.vue?macro=true";
import { default as taskszKnZfmmHo4Meta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/tasks.vue?macro=true";
import { default as _91id_93wIImBxBoI7Meta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/[id].vue?macro=true";
import { default as indexRKimFFqDGRMeta } from "/Users/grk/repos/netzo/pages/catalogs/pricelists/index.vue?macro=true";
import { default as details5xmF5TUCZpMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/details.vue?macro=true";
import { default as filescwJAEJxqALMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/files.vue?macro=true";
import { default as indextAbHoLRBI8Meta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/index.vue?macro=true";
import { default as itemsTjZDeRPl14Meta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/items.vue?macro=true";
import { default as notesy1fplpUAeCMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/notes.vue?macro=true";
import { default as tasksrxdy6U4UVXMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id]/tasks.vue?macro=true";
import { default as _91id_93ZrAozKx8GdMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/[id].vue?macro=true";
import { default as indexzsrhJjumZBMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/index.vue?macro=true";
import { default as itemsAGhLGWN52XMeta } from "/Users/grk/repos/netzo/pages/catalogs/products/items.vue?macro=true";
import { default as detailsMiUNEERpdYMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/details.vue?macro=true";
import { default as filess1c70AjS1ZMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/files.vue?macro=true";
import { default as indexw2j3Kr7qgjMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/index.vue?macro=true";
import { default as items2qM86hzs7TMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/items.vue?macro=true";
import { default as notes0kO47gKdC7Meta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/notes.vue?macro=true";
import { default as tasksGShwAvjRZhMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id]/tasks.vue?macro=true";
import { default as _91id_93LaJcaHgTKCMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/[id].vue?macro=true";
import { default as indexinpKPCKSLAMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/index.vue?macro=true";
import { default as items6o46s6tn2QMeta } from "/Users/grk/repos/netzo/pages/catalogs/services/items.vue?macro=true";
import { default as suppliers1DG9cAZLk6Meta } from "/Users/grk/repos/netzo/pages/catalogs/suppliers.vue?macro=true";
import { default as banksEXkl9dlJqBMeta } from "/Users/grk/repos/netzo/pages/finances/banks.vue?macro=true";
import { default as cfdiskZ666P9qftMeta } from "/Users/grk/repos/netzo/pages/finances/cfdis.vue?macro=true";
import { default as financialaccountsUYoz0k2VRDMeta } from "/Users/grk/repos/latinta-app/app/pages/finances/financialaccounts.vue?macro=true";
import { default as reconciliationswjb6E2pIIkMeta } from "/Users/grk/repos/netzo/pages/finances/reconciliations.vue?macro=true";
import { default as transactionseHOS4hIOSRMeta } from "/Users/grk/repos/netzo/pages/finances/transactions.vue?macro=true";
import { default as uploadKmrxnKKiK1Meta } from "/Users/grk/repos/netzo/pages/finances/upload.vue?macro=true";
import { default as detailsGbXkobQEbRMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/details.vue?macro=true";
import { default as filesYLONPN02q2Meta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/files.vue?macro=true";
import { default as imagesapprovalxUk5tb5PKBMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/imagesapproval.vue?macro=true";
import { default as indexGAIMmmqmXGMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/index.vue?macro=true";
import { default as notesVc1DPNOO2hMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/notes.vue?macro=true";
import { default as tasksKHW6d4dJQZMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/tasks.vue?macro=true";
import { default as _91id_93NYzWFz5gqTMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id].vue?macro=true";
import { default as indexEw2gnTF6BaMeta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/index.vue?macro=true";
import { default as portalJ16seykhg7Meta } from "/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/portal.vue?macro=true";
import { default as indexgmKNHFMWaaMeta } from "/Users/grk/repos/netzo/pages/index.vue?macro=true";
import { default as catalogsqTHraHC7gzMeta } from "/Users/grk/repos/netzo/pages/management/dashboards/catalogs.vue?macro=true";
import { default as finances4leXht6S3LMeta } from "/Users/grk/repos/netzo/pages/management/dashboards/finances.vue?macro=true";
import { default as productionad9ERhfX37Meta } from "/Users/grk/repos/netzo/pages/management/dashboards/production.vue?macro=true";
import { default as saleszDpyx4OYosMeta } from "/Users/grk/repos/netzo/pages/management/dashboards/sales.vue?macro=true";
import { default as notificationspGeYZw4yoiMeta } from "/Users/grk/repos/netzo/pages/notifications.vue?macro=true";
import { default as _91id_93SIrDQ9jYeMMeta } from "/Users/grk/repos/latinta-app/app/pages/portals/installationorders/[id].vue?macro=true";
import { default as detailskE5nZRvPKiMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/details.vue?macro=true";
import { default as filesJdSCawWGtPMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/files.vue?macro=true";
import { default as indexWPhjx96HPFMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/index.vue?macro=true";
import { default as itemsCdagsiexMmMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/items.vue?macro=true";
import { default as notesMV79wBNTs1Meta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/notes.vue?macro=true";
import { default as tasks0HcecZinkNMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id]/tasks.vue?macro=true";
import { default as _91id_93q3IbQzZwCcMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/[id].vue?macro=true";
import { default as approvalLZje5IeDB6Meta } from "/Users/grk/repos/latinta-app/app/pages/production/productionorders/approval.vue?macro=true";
import { default as indexVzEHXcR3TfMeta } from "/Users/grk/repos/netzo/pages/production/productionorders/index.vue?macro=true";
import { default as itemsyScpKCyPW4Meta } from "/Users/grk/repos/netzo/pages/production/productionorders/items.vue?macro=true";
import { default as activitiesc0NG2cJOQ6Meta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/activities.vue?macro=true";
import { default as brancheshXVNGBjiHOMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/branches.vue?macro=true";
import { default as contactsTEjQJ7dRGNMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/contacts.vue?macro=true";
import { default as dealsXeqEYN9e0eMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/deals.vue?macro=true";
import { default as details9Os4WOLwXyMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/details.vue?macro=true";
import { default as filese0rSUUSdYJMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/files.vue?macro=true";
import { default as index5oWhxysNfUMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/index.vue?macro=true";
import { default as notesEiNOsvswLCMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/notes.vue?macro=true";
import { default as productionordersoCpOQ125HSMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/productionorders.vue?macro=true";
import { default as quotesBhcUnCeJU9Meta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/quotes.vue?macro=true";
import { default as salesordersjoDicqJc6uMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/salesorders.vue?macro=true";
import { default as taskszqD2zHCGNTMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id]/tasks.vue?macro=true";
import { default as _91id_93FNyTICKjhVMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/[id].vue?macro=true";
import { default as indexCANBXqBGHdMeta } from "/Users/grk/repos/netzo/pages/sales/accounts/index.vue?macro=true";
import { default as activitiesjlhmkkCprHMeta } from "/Users/grk/repos/netzo/pages/sales/activities.vue?macro=true";
import { default as branchesAYf2syGZNvMeta } from "/Users/grk/repos/netzo/pages/sales/branches.vue?macro=true";
import { default as dealsdtHYyGCUarMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/deals.vue?macro=true";
import { default as detailsBSdF8swPkVMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/details.vue?macro=true";
import { default as filess6pftu7EqnMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/files.vue?macro=true";
import { default as indexTlCYVXMI3sMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/index.vue?macro=true";
import { default as notesMfvehUvGvHMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/notes.vue?macro=true";
import { default as tasks2zpohEmxhmMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id]/tasks.vue?macro=true";
import { default as _91id_93OKbuijFA4YMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/[id].vue?macro=true";
import { default as indexlszF6Ld5wKMeta } from "/Users/grk/repos/netzo/pages/sales/campaigns/index.vue?macro=true";
import { default as contactsNE7TOoYrK0Meta } from "/Users/grk/repos/netzo/pages/sales/contacts.vue?macro=true";
import { default as activitieso7dVsmryvAMeta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/activities.vue?macro=true";
import { default as detailsXPNqynE7AOMeta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/details.vue?macro=true";
import { default as filesYDHxywvfRFMeta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/files.vue?macro=true";
import { default as indexLehhNBuIjeMeta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/index.vue?macro=true";
import { default as installationordersq79CTUm7aUMeta } from "/Users/grk/repos/latinta-app/app/pages/sales/deals/[id]/installationorders.vue?macro=true";
import { default as notesunadSuG6W4Meta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/notes.vue?macro=true";
import { default as productionorders5D0KpTdEuYMeta } from "/Users/grk/repos/latinta-app/app/pages/sales/deals/[id]/productionorders.vue?macro=true";
import { default as quotes62uAHsZps0Meta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/quotes.vue?macro=true";
import { default as salesorders76TqTlAei9Meta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/salesorders.vue?macro=true";
import { default as tasksrlTq8D2c1PMeta } from "/Users/grk/repos/netzo/pages/sales/deals/[id]/tasks.vue?macro=true";
import { default as _91id_93uVRx5QEjg2Meta } from "/Users/grk/repos/latinta-app/app/pages/sales/deals/[id].vue?macro=true";
import { default as index2jjXFZSR4AMeta } from "/Users/grk/repos/netzo/pages/sales/deals/index.vue?macro=true";
import { default as detailsku40vUOu0KMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/details.vue?macro=true";
import { default as filesCTitSLkIPwMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/files.vue?macro=true";
import { default as index8tnHTgxZ2XMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/index.vue?macro=true";
import { default as itemsiThq1go9kvMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/items.vue?macro=true";
import { default as notesANL4LJuVn5Meta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/notes.vue?macro=true";
import { default as salesordersYwMSKQYswPMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/salesorders.vue?macro=true";
import { default as tasksUoMV398svQMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id]/tasks.vue?macro=true";
import { default as _91id_93si9iFEL8uiMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/[id].vue?macro=true";
import { default as indexPIjFCgKGspMeta } from "/Users/grk/repos/netzo/pages/sales/quotes/index.vue?macro=true";
import { default as detailsNrE5TDvpP9Meta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/details.vue?macro=true";
import { default as fileswY4fuRTKp7Meta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/files.vue?macro=true";
import { default as indexH7n5Vv1xG5Meta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/index.vue?macro=true";
import { default as itemsUI6eAI4RfDMeta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/items.vue?macro=true";
import { default as notes7wDRGeNIruMeta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/notes.vue?macro=true";
import { default as tasksoXNYOImii3Meta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id]/tasks.vue?macro=true";
import { default as _91id_93wHWidnknb4Meta } from "/Users/grk/repos/netzo/pages/sales/salesorders/[id].vue?macro=true";
import { default as indexbtVARCyExTMeta } from "/Users/grk/repos/netzo/pages/sales/salesorders/index.vue?macro=true";
import { default as index6ySE1gqxEUMeta } from "/Users/grk/repos/netzo/pages/settings/index.vue?macro=true";
import { default as notificationsCjLnEtH6T5Meta } from "/Users/grk/repos/netzo/pages/settings/notifications.vue?macro=true";
import { default as settingscmRiQrpKA3Meta } from "/Users/grk/repos/netzo/pages/settings.vue?macro=true";
import { default as tasks5WtX9rIUJdMeta } from "/Users/grk/repos/netzo/pages/tasks.vue?macro=true";
import { default as component_45stubwTX1OHZB66Meta } from "/Users/grk/repos/latinta-app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwTX1OHZB66 } from "/Users/grk/repos/latinta-app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-auditlogs",
    path: "/admin/auditlogs",
    component: () => import("/Users/grk/repos/netzo/pages/admin/auditlogs.vue")
  },
  {
    name: "admin-auth-apikeys",
    path: "/admin/auth/apikeys",
    component: () => import("/Users/grk/repos/netzo/pages/admin/auth/apikeys.vue")
  },
  {
    name: "admin-auth-users",
    path: "/admin/auth/users",
    component: () => import("/Users/grk/repos/netzo/pages/admin/auth/users.vue")
  },
  {
    name: "admin-issues",
    path: "/admin/issues",
    component: () => import("/Users/grk/repos/netzo/pages/admin/issues.vue")
  },
  {
    name: "assistant",
    path: "/assistant",
    component: () => import("/Users/grk/repos/netzo/pages/assistant.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordeiGEnjblaJMeta || {},
    component: () => import("/Users/grk/repos/netzo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginmDRjXFrBGjMeta || {},
    component: () => import("/Users/grk/repos/netzo/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerNznzSu1ZFRMeta || {},
    component: () => import("/Users/grk/repos/netzo/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordDtLuaTpmg5Meta || {},
    component: () => import("/Users/grk/repos/netzo/pages/auth/reset-password.vue")
  },
  {
    name: "auth-verify-otp",
    path: "/auth/verify-otp",
    meta: verify_45otpeLWG3WxB6pMeta || {},
    component: () => import("/Users/grk/repos/netzo/pages/auth/verify-otp.vue")
  },
  {
    name: _91id_93doKB6VYy3AMeta?.name,
    path: "/catalogs/applications/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id].vue"),
    children: [
  {
    name: "catalogs-applications-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id]/details.vue")
  },
  {
    name: "catalogs-applications-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id]/files.vue")
  },
  {
    name: "catalogs-applications-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id]/index.vue")
  },
  {
    name: "catalogs-applications-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id]/notes.vue")
  },
  {
    name: "catalogs-applications-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-applications",
    path: "/catalogs/applications",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/applications/index.vue")
  },
  {
    name: _91id_93JhqMMVQVb1Meta?.name,
    path: "/catalogs/categories/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id].vue"),
    children: [
  {
    name: "catalogs-categories-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/details.vue")
  },
  {
    name: "catalogs-categories-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/files.vue")
  },
  {
    name: "catalogs-categories-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/index.vue")
  },
  {
    name: "catalogs-categories-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/notes.vue")
  },
  {
    name: "catalogs-categories-id-products",
    path: "products",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/products.vue")
  },
  {
    name: "catalogs-categories-id-services",
    path: "services",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/services.vue")
  },
  {
    name: "catalogs-categories-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-categories",
    path: "/catalogs/categories",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/categories/index.vue")
  },
  {
    name: _91id_93imUzE0qlmFMeta?.name,
    path: "/catalogs/industries/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id].vue"),
    children: [
  {
    name: "catalogs-industries-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id]/details.vue")
  },
  {
    name: "catalogs-industries-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id]/files.vue")
  },
  {
    name: "catalogs-industries-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id]/index.vue")
  },
  {
    name: "catalogs-industries-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id]/notes.vue")
  },
  {
    name: "catalogs-industries-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-industries",
    path: "/catalogs/industries",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/industries/index.vue")
  },
  {
    name: _91id_93wIImBxBoI7Meta?.name,
    path: "/catalogs/pricelists/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id].vue"),
    children: [
  {
    name: "catalogs-pricelists-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/details.vue")
  },
  {
    name: "catalogs-pricelists-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/files.vue")
  },
  {
    name: "catalogs-pricelists-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/index.vue")
  },
  {
    name: "catalogs-pricelists-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/items.vue")
  },
  {
    name: "catalogs-pricelists-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/notes.vue")
  },
  {
    name: "catalogs-pricelists-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-pricelists",
    path: "/catalogs/pricelists",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/pricelists/index.vue")
  },
  {
    name: _91id_93ZrAozKx8GdMeta?.name,
    path: "/catalogs/products/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id].vue"),
    children: [
  {
    name: "catalogs-products-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/details.vue")
  },
  {
    name: "catalogs-products-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/files.vue")
  },
  {
    name: "catalogs-products-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/index.vue")
  },
  {
    name: "catalogs-products-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/items.vue")
  },
  {
    name: "catalogs-products-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/notes.vue")
  },
  {
    name: "catalogs-products-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-products",
    path: "/catalogs/products",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/index.vue")
  },
  {
    name: "catalogs-products-items",
    path: "/catalogs/products/items",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/products/items.vue")
  },
  {
    name: _91id_93LaJcaHgTKCMeta?.name,
    path: "/catalogs/services/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id].vue"),
    children: [
  {
    name: "catalogs-services-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/details.vue")
  },
  {
    name: "catalogs-services-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/files.vue")
  },
  {
    name: "catalogs-services-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/index.vue")
  },
  {
    name: "catalogs-services-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/items.vue")
  },
  {
    name: "catalogs-services-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/notes.vue")
  },
  {
    name: "catalogs-services-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/[id]/tasks.vue")
  }
]
  },
  {
    name: "catalogs-services",
    path: "/catalogs/services",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/index.vue")
  },
  {
    name: "catalogs-services-items",
    path: "/catalogs/services/items",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/services/items.vue")
  },
  {
    name: "catalogs-suppliers",
    path: "/catalogs/suppliers",
    component: () => import("/Users/grk/repos/netzo/pages/catalogs/suppliers.vue")
  },
  {
    name: "finances-banks",
    path: "/finances/banks",
    component: () => import("/Users/grk/repos/netzo/pages/finances/banks.vue")
  },
  {
    name: "finances-cfdis",
    path: "/finances/cfdis",
    component: () => import("/Users/grk/repos/netzo/pages/finances/cfdis.vue")
  },
  {
    name: "finances-financialaccounts",
    path: "/finances/financialaccounts",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/finances/financialaccounts.vue")
  },
  {
    name: "finances-reconciliations",
    path: "/finances/reconciliations",
    component: () => import("/Users/grk/repos/netzo/pages/finances/reconciliations.vue")
  },
  {
    name: "finances-transactions",
    path: "/finances/transactions",
    component: () => import("/Users/grk/repos/netzo/pages/finances/transactions.vue")
  },
  {
    name: "finances-upload",
    path: "/finances/upload",
    component: () => import("/Users/grk/repos/netzo/pages/finances/upload.vue")
  },
  {
    name: _91id_93NYzWFz5gqTMeta?.name,
    path: "/fulfillment/installationorders/:id()",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id].vue"),
    children: [
  {
    name: "fulfillment-installationorders-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/details.vue")
  },
  {
    name: "fulfillment-installationorders-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/files.vue")
  },
  {
    name: "fulfillment-installationorders-id-imagesapproval",
    path: "imagesapproval",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/imagesapproval.vue")
  },
  {
    name: "fulfillment-installationorders-id",
    path: "",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/index.vue")
  },
  {
    name: "fulfillment-installationorders-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/notes.vue")
  },
  {
    name: "fulfillment-installationorders-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/[id]/tasks.vue")
  }
]
  },
  {
    name: "fulfillment-installationorders",
    path: "/fulfillment/installationorders",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/index.vue")
  },
  {
    name: "fulfillment-installationorders-portal",
    path: "/fulfillment/installationorders/portal",
    meta: portalJ16seykhg7Meta || {},
    component: () => import("/Users/grk/repos/latinta-app/app/pages/fulfillment/installationorders/portal.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/grk/repos/netzo/pages/index.vue")
  },
  {
    name: "management-dashboards-catalogs",
    path: "/management/dashboards/catalogs",
    component: () => import("/Users/grk/repos/netzo/pages/management/dashboards/catalogs.vue")
  },
  {
    name: "management-dashboards-finances",
    path: "/management/dashboards/finances",
    component: () => import("/Users/grk/repos/netzo/pages/management/dashboards/finances.vue")
  },
  {
    name: "management-dashboards-production",
    path: "/management/dashboards/production",
    component: () => import("/Users/grk/repos/netzo/pages/management/dashboards/production.vue")
  },
  {
    name: "management-dashboards-sales",
    path: "/management/dashboards/sales",
    component: () => import("/Users/grk/repos/netzo/pages/management/dashboards/sales.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/Users/grk/repos/netzo/pages/notifications.vue")
  },
  {
    name: "portals-installationorders-id",
    path: "/portals/installationorders/:id()",
    meta: _91id_93SIrDQ9jYeMMeta || {},
    component: () => import("/Users/grk/repos/latinta-app/app/pages/portals/installationorders/[id].vue")
  },
  {
    name: _91id_93q3IbQzZwCcMeta?.name,
    path: "/production/productionorders/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id].vue"),
    children: [
  {
    name: "production-productionorders-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/details.vue")
  },
  {
    name: "production-productionorders-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/files.vue")
  },
  {
    name: "production-productionorders-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/index.vue")
  },
  {
    name: "production-productionorders-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/items.vue")
  },
  {
    name: "production-productionorders-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/notes.vue")
  },
  {
    name: "production-productionorders-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/[id]/tasks.vue")
  }
]
  },
  {
    name: "production-productionorders-approval",
    path: "/production/productionorders/approval",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/production/productionorders/approval.vue")
  },
  {
    name: "production-productionorders",
    path: "/production/productionorders",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/index.vue")
  },
  {
    name: "production-productionorders-items",
    path: "/production/productionorders/items",
    component: () => import("/Users/grk/repos/netzo/pages/production/productionorders/items.vue")
  },
  {
    name: _91id_93FNyTICKjhVMeta?.name,
    path: "/sales/accounts/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id].vue"),
    children: [
  {
    name: "sales-accounts-id-activities",
    path: "activities",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/activities.vue")
  },
  {
    name: "sales-accounts-id-branches",
    path: "branches",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/branches.vue")
  },
  {
    name: "sales-accounts-id-contacts",
    path: "contacts",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/contacts.vue")
  },
  {
    name: "sales-accounts-id-deals",
    path: "deals",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/deals.vue")
  },
  {
    name: "sales-accounts-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/details.vue")
  },
  {
    name: "sales-accounts-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/files.vue")
  },
  {
    name: "sales-accounts-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/index.vue")
  },
  {
    name: "sales-accounts-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/notes.vue")
  },
  {
    name: "sales-accounts-id-productionorders",
    path: "productionorders",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/productionorders.vue")
  },
  {
    name: "sales-accounts-id-quotes",
    path: "quotes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/quotes.vue")
  },
  {
    name: "sales-accounts-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/salesorders.vue")
  },
  {
    name: "sales-accounts-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-accounts",
    path: "/sales/accounts",
    component: () => import("/Users/grk/repos/netzo/pages/sales/accounts/index.vue")
  },
  {
    name: "sales-activities",
    path: "/sales/activities",
    component: () => import("/Users/grk/repos/netzo/pages/sales/activities.vue")
  },
  {
    name: "sales-branches",
    path: "/sales/branches",
    component: () => import("/Users/grk/repos/netzo/pages/sales/branches.vue")
  },
  {
    name: _91id_93OKbuijFA4YMeta?.name,
    path: "/sales/campaigns/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id].vue"),
    children: [
  {
    name: "sales-campaigns-id-deals",
    path: "deals",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/deals.vue")
  },
  {
    name: "sales-campaigns-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/details.vue")
  },
  {
    name: "sales-campaigns-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/files.vue")
  },
  {
    name: "sales-campaigns-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/index.vue")
  },
  {
    name: "sales-campaigns-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/notes.vue")
  },
  {
    name: "sales-campaigns-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-campaigns",
    path: "/sales/campaigns",
    component: () => import("/Users/grk/repos/netzo/pages/sales/campaigns/index.vue")
  },
  {
    name: "sales-contacts",
    path: "/sales/contacts",
    component: () => import("/Users/grk/repos/netzo/pages/sales/contacts.vue")
  },
  {
    name: _91id_93uVRx5QEjg2Meta?.name,
    path: "/sales/deals/:id()",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/sales/deals/[id].vue"),
    children: [
  {
    name: "sales-deals-id-activities",
    path: "activities",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/activities.vue")
  },
  {
    name: "sales-deals-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/details.vue")
  },
  {
    name: "sales-deals-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/files.vue")
  },
  {
    name: "sales-deals-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/index.vue")
  },
  {
    name: "sales-deals-id-installationorders",
    path: "installationorders",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/sales/deals/[id]/installationorders.vue")
  },
  {
    name: "sales-deals-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/notes.vue")
  },
  {
    name: "sales-deals-id-productionorders",
    path: "productionorders",
    component: () => import("/Users/grk/repos/latinta-app/app/pages/sales/deals/[id]/productionorders.vue")
  },
  {
    name: "sales-deals-id-quotes",
    path: "quotes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/quotes.vue")
  },
  {
    name: "sales-deals-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/salesorders.vue")
  },
  {
    name: "sales-deals-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-deals",
    path: "/sales/deals",
    component: () => import("/Users/grk/repos/netzo/pages/sales/deals/index.vue")
  },
  {
    name: _91id_93si9iFEL8uiMeta?.name,
    path: "/sales/quotes/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id].vue"),
    children: [
  {
    name: "sales-quotes-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/details.vue")
  },
  {
    name: "sales-quotes-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/files.vue")
  },
  {
    name: "sales-quotes-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/index.vue")
  },
  {
    name: "sales-quotes-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/items.vue")
  },
  {
    name: "sales-quotes-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/notes.vue")
  },
  {
    name: "sales-quotes-id-salesorders",
    path: "salesorders",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/salesorders.vue")
  },
  {
    name: "sales-quotes-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-quotes",
    path: "/sales/quotes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/quotes/index.vue")
  },
  {
    name: _91id_93wHWidnknb4Meta?.name,
    path: "/sales/salesorders/:id()",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id].vue"),
    children: [
  {
    name: "sales-salesorders-id-details",
    path: "details",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/details.vue")
  },
  {
    name: "sales-salesorders-id-files",
    path: "files",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/files.vue")
  },
  {
    name: "sales-salesorders-id",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/index.vue")
  },
  {
    name: "sales-salesorders-id-items",
    path: "items",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/items.vue")
  },
  {
    name: "sales-salesorders-id-notes",
    path: "notes",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/notes.vue")
  },
  {
    name: "sales-salesorders-id-tasks",
    path: "tasks",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/[id]/tasks.vue")
  }
]
  },
  {
    name: "sales-salesorders",
    path: "/sales/salesorders",
    component: () => import("/Users/grk/repos/netzo/pages/sales/salesorders/index.vue")
  },
  {
    name: settingscmRiQrpKA3Meta?.name,
    path: "/settings",
    component: () => import("/Users/grk/repos/netzo/pages/settings.vue"),
    children: [
  {
    name: "settings",
    path: "",
    component: () => import("/Users/grk/repos/netzo/pages/settings/index.vue")
  },
  {
    name: "settings-notifications",
    path: "notifications",
    component: () => import("/Users/grk/repos/netzo/pages/settings/notifications.vue")
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/Users/grk/repos/netzo/pages/tasks.vue")
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/auth",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/admin",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/management",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/catalogs",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/fulfillment",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/finances",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/production",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/sales",
    component: component_45stubwTX1OHZB66
  },
  {
    name: component_45stubwTX1OHZB66Meta?.name,
    path: "/portals",
    component: component_45stubwTX1OHZB66
  }
]