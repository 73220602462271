import { Liquid } from 'liquidjs'

export async function render(template: string, data: Record<string, unknown>) {
  try {
    const engine = new Liquid()
    const templateParsed = engine.parse(template)
    const result = await engine.render(templateParsed, data)
    return result
  }
  catch (error) {
    console.error('Error rendering template', error)
    return template
  }
}

/**
 * Replaces placeholders in an object with values from the provided context using Liquid templating syntax.
 *
 * This function recursively processes all strings in the given object (including nested objects and arrays),
 * replacing placeholders (e.g., `{{key}}`) with corresponding values from the context.
 *
 * NOTE: recreates the replace() function of 'object-replace-mustache' package since that
 * causes deployment since it uses babel packages which are unsupported in cloudflare runtimes
 *
 * @param object - The object containing placeholders to replace.
 * @param context - The context object providing values for the placeholders.
 * @returns A new object with all placeholders replaced by corresponding values from the context.
 *
 * @example
 * const object = {
 *   greeting: 'Hello, {{name}}!',
 *   nested: {
 *     message: 'Your age is {{age}}.',
 *     list: ['Item {{index1}}', 'Item {{index2}}'],
 *   },
 * };
 *
 * const context = { name: 'Alice', age: 30, index1: 1, index2: 2 };
 *
 * const result = await replace(object, context);
 * console.log(result);
 * // Output:
 * // {
 * //   greeting: 'Hello, Alice!',
 * //   nested: {
 * //     message: 'Your age is 30.',
 * //     list: ['Item 1', 'Item 2'],
 * //   },
 * // }
 */
export async function replace(object: Record<string, unknown>, context: Record<string, unknown>) {
  const engine = new Liquid()

  const replaceRecursive = async (obj: unknown): Promise<unknown> => {
    if (typeof obj === 'string') {
      return await engine.parseAndRender(obj, context)
    }
    else if (Array.isArray(obj)) {
      return await Promise.all(obj.map(replaceRecursive))
    }
    else if (obj && typeof obj === 'object') {
      const result = {}
      for (const key of Object.keys(obj)) {
        result[key] = await replaceRecursive(obj[key])
      }
      return result
    }
    else {
      return obj
    }
  }

  return await replaceRecursive(object)
}
