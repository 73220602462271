
// @ts-nocheck


export const localeCodes =  [
  "es-ES",
  "en-US"
]

export const localeLoaders = {
  "es-ES": [{ key: "../../../nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json", load: () => import("../../../nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json" /* webpackChunkName: "locale__Users_grk_repos_latinta_app_node_modules_nuxt_zod_i18n_dist_runtime_i18n_locales_es_ES_json" */), cache: true },
{ key: "../../../../../netzo/i18n/locales/es-ES.json", load: () => import("../../../../../netzo/i18n/locales/es-ES.json" /* webpackChunkName: "locale__Users_grk_repos_netzo_i18n_locales_es_ES_json" */), cache: true }],
  "en-US": [{ key: "../../../../../netzo/i18n/locales/en-US.json", load: () => import("../../../../../netzo/i18n/locales/en-US.json" /* webpackChunkName: "locale__Users_grk_repos_netzo_i18n_locales_en_US_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "es-ES",
      "dir": "ltr",
      "isCatchallLocale": true,
      "language": "es-ES",
      "name": "Español",
      "files": [
        "/Users/grk/repos/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json",
        "/Users/grk/repos/netzo/i18n/locales/es-ES.json"
      ]
    },
    {
      "code": "en-US",
      "dir": "ltr",
      "isCatchallLocale": true,
      "language": "en-US",
      "name": "English",
      "files": [
        "/Users/grk/repos/netzo/i18n/locales/en-US.json"
      ]
    }
  ],
  "defaultLocale": "es-ES",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": [
    {
      "langDir": "/Users/grk/repos/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales",
      "locales": [
        {
          "code": "es-ES"
        }
      ]
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "es-ES",
    "dir": "ltr",
    "isCatchallLocale": true,
    "language": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "/Users/grk/repos/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/i18n/locales/es-ES.json"
      },
      {
        "path": "/Users/grk/repos/netzo/i18n/locales/es-ES.json"
      }
    ]
  },
  {
    "code": "en-US",
    "dir": "ltr",
    "isCatchallLocale": true,
    "language": "en-US",
    "name": "English",
    "files": [
      {
        "path": "/Users/grk/repos/netzo/i18n/locales/en-US.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
