<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const appConfig = useAppConfig()
const route = useRoute()
const colorMode = useColorMode()

const color = computed(() => (colorMode.value === 'dark' ? '#111827' : 'white'))

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
    // route.meta defined with definePageMeta({ title }) at each page
    { property: 'og:title', content: `${appConfig.netzo.seo.title} - ${route.meta.title}` },
  ],
  // see https://vite-pwa-org.netlify.app/assets-generator/#using-preset-minimal-2023
  link: [
    { rel: 'icon', href: '/favicon.ico', sizes: '48x48' },
    { rel: 'icon', href: '/favicon.svg', sizes: 'any', type: 'image/svg+xml' },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-180x180.png' },
  ],
  htmlAttrs: {
    lang: 'es',
  },
})

useSeoMeta(appConfig.netzo.seo)

const breakpoints = useBreakpoints(breakpointsTailwind)
const breakpointsCurrentReal = computed(() => breakpoints.current().value) // ['sm', 'md', 'lg', 'xl', '2xl']
const breakpointsCurrent = useCookie('breakpoints-current', { default: () => breakpointsCurrentReal.value })
watch(breakpointsCurrentReal, value => breakpointsCurrent.value = value, { immediate: true })
</script>

<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
  <UModals />
  <USlideovers />
</template>

<style>
:root {
  --subpage-height: calc(100% - var(--header-height));
}

.is-subpage {
  height: var(--subpage-height);
  overflow-y: auto;
}
</style>
