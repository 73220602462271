export default defineAppConfig({
  netzo: {
    seo: {
      favicon: '/favicon.svg',
      title: 'La Tinta',
      description: 'Software de gestión de empresas.',
      ogUrl: process.env.BASE_URL!!,
      // NOTE: whatsapp requires image < 300kb (freeconvert.com)
      ogImage: `${process.env.BASE_URL}/social-card.png`,
      twitterTitle: 'La Tinta',
      twitterDescription: 'Software de gestión de empresas.',
      twitterImage: `${process.env.BASE_URL}/social-card.png`,
      twitterCard: 'summary_large_image',
    },
    auth: {
      allowUserRegistration: false,
    },
    preferences: {
      // text: 'upper',
    },
  },
  ui: {
    primary: 'pink',
    gray: 'neutral',
  },
})
