<script setup lang="ts">
const model = defineModel<string | undefined | null>()
</script>

<template>
  <InputText v-model.defaultcase="model">
    <template #leading>
      <UIcon name="i-mdi-signature" />
    </template>
    <template v-if="model?.length" #trailing>
      <UIcon name="i-mdi-check-cicle" class="bg-green-500" />
    </template>
  </InputText>
</template>
