<script setup lang="ts">
import { render } from '#netzo/utils/core/templates'
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { computedAsync } from '@vueuse/core'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Message>
  onSubmit: (event: FormSubmitEvent<Partial<Message>>) => void
  openNestedCallback: (data: Partial<Message>) => void
  title?: string
  disabledFields?: (keyof Message | string)[]
  readonly?: boolean
  accountId: string
}>()

const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Message>>(getDefaultMessage(props.data))

const messages$ = useMessages()
const contacts$ = useContacts()

const $contacts = useFetch<Contact[]>('/api/db/contacts', {
  query: { type: 'sales', accountId: [props.accountId], $columns: ['id', 'name', 'image', 'email', 'phone'] },
  default: () => [],
})

const utils = useMessagesUtils({ $contacts })

const optionsContactIdWithPhones = computed(() => {
  return utils.options.contactId.value.map((option) => {
    const contact = utils.maps.contactId.value.get(option.value)
    return {
      ...option,
      label: `${option.label} ${toPhoneString(contact?.phone)}`,
      disabled: !contact?.phone?.code || !contact?.phone?.number,
    }
  })
})

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const preview = computedAsync(async () => {
  const option = utils.options.templateId.find(option => option.value === state.value?.templateId)
  return {
    ...option,
    from: 'LA TINTA',
    subject: state.value.subject,
    body: await render(option!.template, state.value.data),
    button: option?.button?.(state.value),
  }
}, { from: 'LA TINTA', to: state.value.to, body: '' })

const loading = ref(false)

const onSubmitMessage = async (event: FormSubmitEvent<Partial<Message>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  try {
    loading.value = true
    await props.onSubmit(event)
    useToastAlert().success(`Se envió el mensaje.`)
  }
  catch (error) {
    console.error(error)
    useToastAlert().error(`Ocurrió un error al enviar el mensaje.`)
  }
  // NOTE: props.onSubmit(event) handles slideover.close()
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>

    <UForm
      id="form.messages"
      :validate-on="['input', 'submit']"
      :schema="messages$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitMessage"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            name="templateId"
            label="Plantilla"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.templateId"
              :options="utils.options.templateId"
              :disabled="disabledFields?.includes('templateId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto"
            name="contactId"
            :required="isRequired(contacts$.schema, 'contactId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: optionsContactIdWithPhones,
                disabled: disabledFields?.includes('contactId'),
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <AppMessagesPreview :preview="preview" />

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.messages"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
