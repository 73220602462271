<script setup lang="ts">
const model = defineModel<number | undefined>()

const color = computed(() => {
  if (!model.value) return 'gray'
  if (model.value <= 3) return 'red'
  if (model.value <= 6) return 'amber'
  if (model.value <= 7) return 'orange'
  return 'green'
})
</script>

<template>
  <div>
    <URange
      v-model="model"
      :min="1"
      :max="10"
      :step="1"
      :color="color"
      :label="model"
    />
  </div>
</template>
