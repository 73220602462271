import type { Payment, PdfSettings } from '#imports'
import { format } from 'date-fns'

export const defaultAppVariables = {
  currency: 'MXN', // used for price lists
}

export const defaultPayment: Payment = {
  satCfdiUse: 'G03',
  satPaymentMethod: 'PUE',
  satPaymentForm: '03',
  conditions: 'NET0',
  currency: 'MXN',
  exchangeRate: {
    value: 1,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  },
}

export const defaultLegalInformation = {
  taxId: 'IIM1704197MA',
  taxCountryCode: 'MX',
  legalName: 'IMPRESIONES IMPARTE',
  satFiscalRegime: '626',
  phone: '8123557132',
  email: 'info@latinta.mx',
  address: {
    street: 'IGNACIO ALLENDE',
    numberExterior: '115',
    neighborhood: 'EMILIANO ZAPATA',
    city: 'MONTERREY',
    state: 'NUEVO LEON',
    country: 'Mexico',
    postalCode: '64390',
  },
}

export const defaultPdfSettings: PdfSettings = {
  watermark: {
    enabled: false,
    text: 'LATINTA',
    bold: true,
    italic: false,
  },
  images: false,
  details: true,
  pageSize: 'LETTER',
}
