<script setup lang="ts">
import { useSortable } from '@vueuse/integrations/useSortable'
import type { ZodSchema } from 'zod'

const model = defineModel<Installationorder['items']>({ default: () => [] })

const props = defineProps<{
  placeholder?: string
  schema: ZodSchema
}>()

const toItem = (item: Installationorder['items'][number]) => ({
  text: item.text,
  images: item.images,
  id: Math.random().toString(36).substr(2, 9),
})

const items = ref(model.value?.map(toItem) ?? [])

const removeAtIndex = (index: number) => {
  items.value = items.value.filter((_, i) => i !== index)
}

watch(items, (value) => {
  model.value = value.map((value) => {
    return { text: value.text, images: value.images }
  })
}, { deep: true })

const onInputLast = async (event) => {
  const value = event.target?.value?.trim()
  if (value) {
    items.value.push(toItem(value)) // Add the new entry
    await nextTick() // Wait for the DOM to update
    const index = items.value.length - 1 // Get the index of the new entry
    document.getElementById(`entries-text-${index}`)?.focus() // Focus on the new entry
    document.getElementById('last-entry')!.value = '' // Clear the last entry input
  }
}
// sortable:
const el = useTemplateRef('el')
useSortable(el, items, { handle: '.handle', animation: 100 })
</script>

<template>
  <fieldset>
    <div ref="el">
      <fieldset
        v-for="(item, index) in items"
        :key="`entry-${item.id}`"
        class="grid grid-cols-[min-content,auto] gap-1 mb-2 text-sm p-4 rounded-lg"
        :class="FIELDSET"
      >
        <div class="flex flex-col items-center">
          <UButton
            variant="ghost"
            icon="i-mdi-drag"
            size="xs"
            color="gray"
            class="handle cursor-move"
          />
          <UButton
            variant="ghost"
            color="red"
            size="xs"
            icon="i-mdi-close"
            @click="removeAtIndex(index)"
          />
        </div>
        <div class="grid gap-1">
          <UTextarea
            :id="`entries-text-${index}`"
            v-model="item.text"
            resize
            :rows="2"
            placeholder="Descripción de la tarea a realizar"
          />
          <InputImages
            v-model="item.images"
            table-name="installationorders"
            :record-id="item.id"
          />
        </div>
      </fieldset>
    </div>
    <UTextarea
      id="last-entry"
      :rows="1"
      resize
      :placeholder="placeholder ?? 'Ingresa una nueva tarea'"
      class="col-span-2"
      @input="onInputLast"
    />
  </fieldset>
</template>
