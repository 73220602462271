<script setup lang="ts">
import { z } from '#netzo/shared/utils/zod'
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<boolean>()

const props = defineProps<{
  data: Partial<Installationorder>
  onSubmit: (event: FormSubmitEvent<Partial<Installationorder['approval']>>) => Promise<void>
}>()

const state = ref<Partial<Installationorder['approval']>>(props.data?.data ?? {})

const schema = z.object({
  approvedBy: z.string().nonempty('Firma es requerida').min(3).max(100),
  approvedAt: z.string(),
  nps: z.number().int().min(0).max(10).default(0),
  text: z.string().nullable().optional(),
})

const optionNps = computed(() => optionsShared.nps.find(opt => opt.value === state.value.nps))

const acceptedTerms = ref(false)
const disabled = computed(() => !state.value.approvedBy?.length || !state.value.nps || !acceptedTerms.value)
const loading = ref(false)

const onSubmitWithLoading = async (event: FormSubmitEvent<Partial<Installationorder['approval']>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    title="Finalizar Aprobación"
    description="Firma con tu nombre completo y calificanos para confirmar la aprobación. Tu opinión es muy importante para nosotros."
    :ui="{
      description: 'text-xs text-gray-500',
    }"
  >
    <UForm
      id="form.installationorders-approval"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      class="space-y-6"
      @error="onFormError"
      @submit="onSubmitWithLoading"
    >
      <UFormGroup
        label="Firma con tu nombre completo"
        name="approvedBy"
        required
      >
        <InputSignature
          v-model="state.approvedBy"
          @blur="() => state.approvedAt = new Date().toISOString()"
        />
      </UFormGroup>

      <UFormGroup
        label="Califica tu experiencia"
        name="nps"
        required
        class="mb-4"
        :hint="state.nps ? `${state.nps} = ${optionNps?.label}` : ''"
        :ui="{
          hind: 'text-xs text-gray-500',
        }"
      >
        <InputNps v-model="state.nps" />
      </UFormGroup>

      <UFormGroup
        label="Comentarios adicionales"
        name="text"
      >
        <UTextarea
          v-model="state.text"
          :rows="5"
          autoresize
        />
      </UFormGroup>

      <div class="grid grid-cols-1 gap-2">
        <UCheckbox
          v-model="acceptedTerms"
          label="Acepto los términos y condiciones"
          required
        />
        <span class="text-xs text-gray-500">
          Por medio de la presente formalizo que estoy satisfecho y  doy por concluída la instalación realizada el dia de hoy. A su vez, en el caso que no cuente con crédito con nosotros. Formalizo el acuerdo de liquidar el finiquito correspondiente a esta instalación en un plazo no mayor a 5 días a partir del día de hoy. Sirve la presente para confirmar que estoy consiente que al no liquidar el finiquito pendiente  al transcurrir el periodo anteriormente mencionado, estoy sujeto a que se retire el producto instalado o que se cobren intereses moratorios correspondientes al finiquito.
        </span>
      </div>
    </UForm>

    <template #footer>
      <UButton
        form="form.installationorders-approval"
        type="submit"
        label="Finalizar Aprobación"
        color="green"
        block
        :loading="loading"
        :disabled="disabled"
      />
    </template>
  </UDashboardModal>
</template>
