import type { Options } from '#netzo/shared/types/core'
import { createId, createUid } from '#netzo/utils/core/db'
import type { CalendarEvent } from '@schedule-x/calendar'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import { merge } from 'es-toolkit/compat'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

export const getDefaultInstallationorder = (data: Partial<Installationorder>) => {
  const uid = createUid(new Date(), 'OI') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'complete',
    status: 'new',
    priority: '2',
    uid: uid,
    name: uid,
    isDatetime: true,
    surfaces: [],
    openHours: '8:00 AM - 5:00 PM',
    items: [],
    approval: {
      images: [],
      text: null,
      approvedAt: null,
      approvedBy: null,
      nps: 0,
    },
    useAccountAddress: true,
    address: {
      country: 'Mexico',
    },
    filePdf: {},
    pdfSettings: {
      ...defaultPdfSettings,
      images: true,
    },
  }, data)
}

export const getDefaultMessageFromInstallationorder = (data: Installationorder, templateId: string): Partial<Message> => {
  const template = templateId
    ? optionsMessages.templateId.find(template => template.value === templateId)
    : optionsMessages.templateId[0]
  return {
    templateId: template!.value,
    subject: template!.label,
    body: template!.template,
    installationorderId: data.id,
    data: {
      name: data.name === data.uid ? data.name : `${data.name} (${data.uid})`,
      address: data.address ? toAddressString(data.address) : '-',
      date: data.dateStart ? format(new Date(data.dateStart), 'EEEE, d \'de\' MMMM \'del\' yyyy', { locale: es }) : '-',
      time: data.isDatetime && data.dateStart ? format(new Date(data.dateStart), 'h:mm a', { locale: es }) : '-',
    },
  }
}

export const getInstallationorderInformation = (data: Partial<InstallationorderWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsInstallationorders.type, 'value'),
    status: toMapByKey(optionsInstallationorders.status, 'value'),
    priority: toMapByKey(optionsInstallationorders.priority, 'value'),
  }
  const localDateStart = data.dateStart ? parseISO(data.dateStart) : null
  const dateStart = localDateStart
    ? data.isDatetime
      ? localDateStart.toLocaleString()
      : localDateStart.toLocaleDateString()
    : null
  const localDateEnd = data.dateEnd ? parseISO(data.dateEnd) : null
  const dateEnd = localDateEnd
    ? data.isDatetime
      ? localDateEnd.toLocaleString()
      : localDateEnd.toLocaleDateString()
    : null
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Prioridad', maps.priority.get(data.priority!)?.label],
    ['Fecha de Inicio', dateStart],
    ['Fecha de Cierre', dateEnd],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsInstallationorders = {
  type: [
    { value: 'complete', label: 'Completa', description: 'La orden de venta se está cumpliendo en su totalidad, sin elementos pendientes.', icon: 'i-mdi-check-circle-outline', color: 'green' },
    { value: 'partial', label: 'Parcial', description: 'La orden de venta se está cumpliendo parcialmente; quedan elementos pendientes.', icon: 'i-mdi-alert-circle-outline', color: 'yellow' },
    { value: 'rework', label: 'Retrabajo', description: 'Se está realizando un retrabajo debido a problemas con un cumplimiento anterior.', icon: 'i-mdi-wrench-outline', color: 'orange' },
  ],
  status: [
    {
      value: 'new',
      label: 'Nueva',
      description: 'Orden creada pero pendiente de agendar y preparar.',
      descriptionPortal: 'La orden de instalación ha sido creada y sera procesada por nuestro equipo. En breve te contactaremos para agendar la fecha de instalación.',
      icon: 'i-mdi-plus-circle',
      color: 'gray',
    },
    {
      value: 'unscheduled',
      label: 'Reagendar',
      description: 'Orden pendiente de re-agendar.',
      descriptionPortal: 'Haz rechazado la fecha de instalación propuesta. Un asesor se pondra en contacto contigo o recibiras una nueva propuesta para la fecha de tu instalación via Whatsapp.',
      icon: 'i-mdi-calendar-clock',
      color: 'amber',
    },
    {
      value: 'scheduled',
      label: 'Agendada',
      description: 'Orden está programada y en espera de confirmación.',
      descriptionPortal: 'Revisa el horario de instalación propuesta y confirma tu disponibilidad. Si deseas reprogramar, comunicalo cuanto antes dando click en "Rechazar".',
      icon: 'i-mdi-calendar',
      color: 'pink',
    },
    {
      value: 'confirmed',
      label: 'Confirmada',
      description: 'Orden está confirmada y en espera de ser ejecutada en la fecha y hora asignadas.',
      descriptionPortal: 'Nuestro equipo arribara para su ejecución a la fecha y hora asignada. Si deseas reprogramar la fecha de instalación, por favor comunicalo con nosotros cuanto antes.',
      icon: 'i-mdi-calendar-check',
      color: 'blue',
    },
    {
      value: 'in-progress',
      label: 'En Ejecución',
      description: 'Orden en proceso activo de ejecución.',
      descriptionPortal: 'Nuestro equipo se encuentra en el domicilio realizando tu instalación. Al finalizar la instalación, se te notificara via Whatsapp y solicitaremos tu conformidad.',
      icon: 'i-mdi-progress-clock',
      color: 'orange',
    },
    {
      value: 'revision',
      label: 'En Revisión',
      description: 'Orden en proceso de revisión para su re-aprobación.',
      descriptionPortal: 'Has solicitado una revisión de la instalación realizada. Nuestro equipo se pondra en contacto contigo para coordinar la revisión y re-aprobación de la instalación.',
      icon: 'i-mdi-progress-check',
      color: 'red',
    },
    {
      value: 'approval',
      label: 'Aprobación',
      description: 'Orden en espera de aprobación final.',
      descriptionPortal: 'Tu orden ha sido completada y se encuentra en espera de tu aprobación final. Por favor revisa el trabajo realizado y aprueba la orden si estas satisfecho.',
      icon: 'i-mdi-check-decagram',
      color: 'blue',
    },
    {
      value: 'completed',
      label: 'Finalizada',
      description: 'Orden completada con éxito y aprobada para su cierre.',
      descriptionPortal: 'Tu orden ha sido completada con éxito y aprobada para su cierre. Gracias por tu preferencia.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
    {
      value: 'canceled',
      label: 'Cancelada',
      description: 'Orden cancelada. No se realizará ninguna acción adicional.',
      descriptionPortal: 'Esta orden ha sido cancelada, no se requiere ningun acción adicional de tu parte.',
      icon: 'i-mdi-close-circle',
      color: 'red',
    },
  ],
  priority: optionsShared.priority,
  surfaces: [
    { value: 'smooth-drywall', label: 'Tabla roca lisa', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'rough-drywall', label: 'Tabla roca rugosa', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'smooth-concrete', label: 'Concreto liso', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'rough-concrete', label: 'Concreto rugoso', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'glass', label: 'Vidrio', icon: 'i-mdi-window-open-variant', color: 'orange' },
    { value: 'ceiling', label: 'Techo', icon: 'i-mdi-home', color: 'red' },
    { value: 'vehicle', label: 'Vehículo', icon: 'i-mdi-car', color: 'purple' },
    { value: 'wood', label: 'Madera', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'metal', label: 'Metal', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'plastic', label: 'Plástico', icon: 'i-mdi-wall', color: 'blue' },
    { value: 'other', label: 'Otro', icon: 'i-mdi-shape', color: 'gray' },
  ],
} satisfies Options

export const optionsInstallationordersMap = {
  Tipo: optionsInstallationorders.type,
  Estado: optionsInstallationorders.status,
  Prioridad: optionsInstallationorders.priority,
}

export const relationsInstallationorders: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  contactApproval: true,
  deal: true,
  messages: true,
  productionorder: true,
  salesorder: true,
  user: true,
}

export type InstallationorderWithRelations = Installationorder & {
  account?: Account
  branch?: Branch
  contact?: Contact
  contactApproval?: Contact
  productionorder?: Productionorder
  salesorder?: Salesorder
  user?: User
}

export const installationorderToViewCalendarEvent = (calendarId: keyof Installationorder, utils: InstallationordersUtils) => {
  return (row: Installationorder): CalendarEvent | null => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.text!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionInstallationorders = async (
  data: InstallationorderWithRelations,
  utils: InstallationordersUtils,
  salesorder: SalesorderWithRelations,
): Promise<TDocumentDefinitions> => {
  data.data ??= {}

  const info: TDocumentInformation = {
    title: `${data.name && data.uid && (data.name !== data.uid) ? `${data.name} (${data.uid})` : data.uid}`,
    subject: 'Orden de Instalación La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, fit: [80, 200], margin: [5, 5] },
      {
        stack: [
          { text: 'Orden de Instalación', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Nombre: ', bold: true }, data.name], alignment: 'right' },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid}`], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status)?.label], alignment: 'right' },
          { text: [{ text: 'Prioridad: ', bold: true }, utils.maps.priority.get(data.priority)?.label], alignment: 'right' },
          data.dateStart && { text: [{ text: 'Programación: ', bold: true }, new Date(data.dateStart).toLocaleDateString(), ' - ', new Date(data.dateEnd).toLocaleDateString()], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionInstallation: ContentColumns = {
    columns: [
      {
        stack: [
          { text: 'Detalles', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Cliente: ', bold: true }, data.account?.name], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Negocio: ', bold: true }, data.deal?.name], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Orden de Venta: ', bold: true }, data.salesorder?.name], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Orden de Producción: ', bold: true }, data.productionorder?.name], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Horario: ', bold: true }, data.openHours], margin: [0, 0, 0, 2] },
        ],
        width: '25%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Instalación', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Instalador: ', bold: true }, data.user?.name], margin: [0, 0, 0, 2] },
          { text: [{ text: 'Contacto: ', bold: true }, `${data.contact?.name} (${toPhoneString(data.contact?.phone)})`], margin: [0, 0, 0, 2] },
          data.dateStart && { text: [{ text: 'Programación: ', bold: true }, new Date(data.dateStart).toLocaleDateString(), ' - ', new Date(data.dateEnd).toLocaleDateString()], margin: [0, 0, 0, 2] },
          data.surfaces.length && { text: [{ text: 'Superficies: ', bold: true }, data.surfaces.map(surface => utils.maps.surfaces.get(surface)?.label).join(', ')], margin: [0, 0, 0, 2] },
          data.removal && { text: [{ text: 'Retirar: ', bold: true }, data.removal], margin: [0, 0, 0, 2] },
          data.maxHeight && { text: [{ text: 'Altura max.: ', bold: true }, data.maxHeight], margin: [0, 0, 0, 2] },
          ...(data.address.street
            ? [
                (data.branchId && { text: [{ text: 'Sucursal: ', bold: true }, data.branch?.name || ''], margin: [0, 0, 0, 2] }),
                { text: [{ text: 'Dirección: ', bold: true }, toAddressString(data.address) || ''], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : [
                { text: [{ text: 'Dirección: ', bold: true }, 'Pendiente'], margin: [0, 0, 0, 2] },
              ]),
        ],
        width: '25%',
        margin: [0, 10],
      },
      {
        stack: [
          { text: 'Aprobación', style: 'header', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Contacto: ', bold: true }, `${data.contactApproval?.name} (${toPhoneString(data.contactApproval?.phone)})`], margin: [0, 0, 0, 2] },
          ...(data.approval.approvedAt
            ? [
                { text: [{ text: 'Aprobado por: ', bold: true }, data.approval?.approvedBy], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Aprobado en: ', bold: true }, new Date(data.approval?.approvedAt).toLocaleDateString()], margin: [0, 0, 0, 2] },
                { text: [{ text: 'NPS: ', bold: true }, data.approval?.nps], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Comentarios: ', bold: true }, data.approval?.text], margin: [0, 0, 0, 2] },
              ] as Content[] // Explicitly cast the array to Content[]
            : [
                { text: [{ text: 'Aprobado por: ', bold: true }, 'Pendiente'], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Aprobado en: ', bold: true }, 'Pendiente'], margin: [0, 0, 0, 2] },
                { text: [{ text: 'NPS: ', bold: true }, 'Pendiente'], margin: [0, 0, 0, 2] },
                { text: [{ text: 'Comentarios: ', bold: true }, 'Pendiente'], margin: [0, 0, 0, 2] },
              ]),
        ],
        width: '25%',
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionInstallationorderitems: Content = {
    table: {
      dontBreakRows: false,
      headerRows: 0,
      widths: data.pdfSettings?.images ? ['50%', '50%'] : ['*'],
      body: [
        ...(await Promise.all(data!.items!.map(async (item, index: number) => [
          ...data.pdfSettings?.images ? [
            {
              stack: [
                await Promise.all(
                  item.images.map(async (image) => {
                    if (image) {
                      return {
                        image: await getBase64ImageFromURL(image),
                        fit: [250, 250],
                        margin: [5, 5, 5, 5],
                        alignment: 'center',
                      }
                    }
                    return null // Explicitly return null for falsy values
                  }),
                ),
              ].filter(Boolean), // Apply filter after awaiting Promise.all
            },
          ] : [],
          {
            stack: [
              { text: [item.text], margin: [5, 2] },
            ].filter(Boolean),
          },
        ]))),
      ].filter(Boolean),
    },
    layout: {
      defaultBorder: true,
    },
  }

  return {
    language: 'es-MX',
    compress: true,
    watermark: data.pdfSettings?.watermark?.enabled
      ? {
          text: data.pdfSettings?.watermark.text,
          color: data.pdfSettings?.watermark.color,
          bold: data.pdfSettings?.watermark.bold,
          italics: data.pdfSettings?.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionInstallation,
      data.items.length && sectionInstallationorderitems,
    ],
    footer: (currentPage: number, pageCount: number) => ({
      columns: [
        { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
        {
          stack: [
            { text: [
              { text: 'Generado con ' },
              { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
              { text: ' ' },
              { text: `el ${new Date().toLocaleDateString()}` }],
            }],
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
        {
          text: `Página ${currentPage.toString()} de ${pageCount}`,
          alignment: 'right',
          margin: [0, 10, 20, 0],
        },
      ],
      widths: ['auto', '*', 'auto'],
    }),
    styles: {
      header: { fontSize: 10, bold: true, margin: [0, 0, 10, 5] },
      tableHeader: { bold: true, fillColor: '#aaa', fillOpacity: 0.25, margin: [5, 5], noWrap: true },
      tableRow: { bold: false, margin: [5, 5] },
    },
    defaultStyle: { fontSize: 8 },
    pageSize: data.pdfSettings?.pageSize || 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}

export const viewSettingsInstallationorders: ViewSettings = {
  tableName: 'installationorders',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePdf',
      label: 'PDF',
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'user.name',
      label: 'Instalador',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contact.name',
      label: 'Contacto instalación',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'branch.name',
      label: 'Sucursal',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'address',
      label: 'Dirección',
      class: 'min-w-[300px]',
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'contactApproval.name',
      label: 'Contacto aprobación',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'approval.approvedBy',
      label: 'Aprobado por',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'approval.approvedAt',
      label: 'Aprobado en',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'approval.nps',
      label: 'NPS',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'productionorder.name',
      label: 'Orden de Producción',
      sortable: true,
    },
    {
      key: 'productionorder.status',
      label: 'Estado (OP)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorder.name',
      label: 'Orden de venta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'deal.name',
      label: 'Orden de venta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsInstallationorders.type },
    { label: 'Estado', value: 'status', options: optionsInstallationorders.status },
    { label: 'Prioridad', value: 'priority', options: optionsInstallationorders.priority },
  ],
}
