<script setup lang="ts">
import type { Phone } from '#netzo/shared/types/app'
import { vMaska } from 'maska/vue'

const model = defineModel<Phone>({ default: () => ({ code: null, number: null, ext: null }) })

const props = defineProps<{
  label?: string
  disabled?: boolean
}>()

const selectedCountry = ref(model.value.code ?? '52')
const countriesByCode = toMapByKey(optionsShared.countryCode, 'code')
const selectedOption = computed(() => countriesByCode.get(selectedCountry.value))
console.log('selectedOption', selectedOption.value)
const onUpdateCode = (code: string) => {
  model.value.code = code
}
watch(() => model.value.number, () => {
  if (model.value.number && !model.value.code) {
    model.value.code = selectedCountry.value
  }
})
</script>

<template>
  <div v-if="model" class="flex gap-2 items-center">
    <SelectMenuBase
      v-model="selectedCountry"
      :options="optionsShared.countryCode"
      :disabled="disabled"
      value-attribute="code"
      @update:model-value="onUpdateCode"
    >
      <template #option="{ option }">
        <div class="flex items-center">
          <UIcon :name="option.icon" class="w-4 h-4" />
          <span class="mx-3">{{ option.label }}</span>
          <span class="text-gray-500 dark:text-gray-400">
            +{{ option.code }}
          </span>
        </div>
      </template>
    </SelectMenuBase>
    <InputText
      v-model.trim="model.number"
      v-maska="selectedOption?.maskPhone"
      type="tel"
      label="Teléfono"
      :disabled="disabled"
      class="flex-1"
    />
    <InputText
      v-model.trim="model.ext"
      type="tel"
      label="Teléfono"
      :disabled="disabled"
      placeholder="ext."
      class="flex-0 w-20"
    />
  </div>
</template>
