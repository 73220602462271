import { AppMessagesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppMessagesSlideoverProps = ComponentProps<typeof AppMessagesSlideover>

export type MessagesUtils = ReturnType<typeof useMessagesUtils>

export const useMessages = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Message>('messages')

  const slideoverOpenCreate = (props: AppMessagesSlideoverProps) => {
    return new Promise<Message>((resolve, reject) => {
      const data = getDefaultMessage(props.data)
      slideover.open(AppMessagesSlideover, {
        title: 'Enviar mensaje',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            // DISABLED: message creation handled on the server for better error handling
            // const result = await onSubmitCreate(event.data)
            const contact = await $fetch<Contact>(`/api/db/contacts/${event.data.contactId}`)
            const { code, number } = contact?.phone ?? {}
            event.data.to = `${code}${number}`
            console.log(contact)
            console.log(event.data)
            const result = await $fetch<Message>(`/api/messages`, { method: 'POST', body: event.data })
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const schema = z.object({
    templateId: z.string(),
    contactId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Message, refresh: () => Promise<void>, utils: MessagesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'messages', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'messages', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'messages', row, refresh, utils),
      getDropdownItem('files', 'messages', row, refresh, utils),
    ],
    [
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'messages', row, refresh, utils),
      getDropdownItem('immutable', 'messages', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'messages', row, refresh, utils),
      getDropdownItem('delete', 'messages', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    schema,
    getDropdownItems,
  }
}

export const useMessagesUtils = ({ $contacts }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
} = {}) => {
  const options = {
    ...optionsMessages,
    contactId: computed(() => $contacts?.data.value.map(toOption)),
  }

  const maps = {
    status: toMapByKey(optionsMessages.status, 'value'),
    templateId: toMapByKey(optionsMessages.templateId, 'value'),
    tableName: toMapByKey(optionsShared.tableName, 'value'),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
  }

  return { options, maps }
}
