import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultBranch = (data: Partial<Branch>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'shipping',
    phone: {},
    address: {
      country: 'Mexico',
    },
    tags: [],
    data: getDefaultBranchData(data?.data ?? {}),
  }, data)
}

export const getBranchInformation = (data: Partial<Branch>) => {
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Teléfono', toPhoneString(data.phone)],
    ['Dirección', toAddressString(data?.address as Address)],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsBranches = {
  type: [
    { value: 'shipping', label: 'Envío', description: 'Sucursal para envío de productos', color: 'blue', icon: 'i-mdi-truck' },
    { value: 'office', label: 'Oficina', description: 'Sucursal para oficina', color: 'lime', icon: 'i-mdi-office-building' },
    { value: 'warehouse', label: 'Almacén', description: 'Sucursal para almacenamiento de productos', color: 'orange', icon: 'i-mdi-package-variant-closed' },
    { value: 'store', label: 'Tienda', description: 'Sucursal para venta de productos', color: 'green', icon: 'i-mdi-store' },
    { value: 'plant', label: 'Planta', description: 'Sucursal para producción de productos', color: 'purple', icon: 'i-mdi-factory' },
    { value: 'other', label: 'Otro', description: 'Otro tipo de sucursal', color: 'gray', icon: 'i-mdi-help-circle' },
  ],
} satisfies Options

export const optionsBranchesMap = {
}

export const relationsBranches: Record<string, boolean> = {
  account: true,
  activities: true,
  contacts: true,
  deals: true,
  files: true,
  // options: true,
}

export type BranchWithRelations = Branch & {
  account: Account
  activities: Activity[]
  contacts: Contact[]
  deals: Deal[]
  files: File[]
  options: Option[]
}

export const viewSettingsBranches: ViewSettings = {
  tableName: 'branches',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'cards',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'phone',
      label: 'Teléfono',
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'address',
      label: 'Dirección',
      class: 'min-w-[300px]',
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'deals',
      label: 'Negocios',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
