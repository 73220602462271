<script setup lang="ts">
const model = defineModel<Installationorder>({ required: true })

const props = defineProps<{
  utils: InstallationordersUtils
  disabled?: boolean
  schema: ZodSchema<unknown>
  refresh: () => Promise<void>
}>()
</script>

<template>
  <fieldset :class="FIELDSET_FULL" :disabled="disabled">
    <UFormGroup
      :label="`NPS (Net Promoter Score)`"
      name="approval.nps"
      class="mt-1"
      :hint="`${model.approval.nps}/10`"
    >
      <InputNps v-model="model.approval.nps" :disabled="!['approval'].includes(model.status)" />
    </UFormGroup>

    <UFormGroup label="Comentarios" name="approval.text">
      <UTextarea
        v-model="model.approval.text"
        autoresize
        :disabled="!['approval'].includes(model.status)"
      />
    </UFormGroup>

    <UFormGroup label="Aprobado por" name="approval.approvedBy">
      <InputSignature
        v-model="model.approval.approvedBy"
        :disabled="!['approval'].includes(model.status)"
        @blur="() => model.approval.approvedAt = new Date().toISOString()"
      />
    </UFormGroup>

    <UFormGroup label="Aprobado en" name="approval.approvedAt">
      <InputDatetime
        v-model:date="model.approval.approvedAt"
        :model-value:is-datetime="true"
        hide-checkbox
        :disabled="!['approval'].includes(model.status)"
      />
    </UFormGroup>

    <AppAlert
      type="info"
      description="Para poder cambiar los campos de la aprobación, es necesario que el item se encuentre en estado de aprobación."
      class="mt-4"
    />
  </fieldset>
</template>
