<script setup lang="ts">
import type { z } from '#netzo/shared/utils/zod'

const model = defineModel<Address>({ required: true })
const name = defineModel<string>('name', { required: true })

const props = defineProps<{
  label?: string
  path: string // necessary for nested objects
  disabled?: boolean
  disabledFields?: (keyof Address | string)[]
  schema: z.ZodObject<unknown>
}>()
</script>

<template>
  <fieldset :class="FIELDSET_FULL_2">
    <UFormGroup
      :label="label ?? undefined"
      class="md:col-span-2"
    />
    <UFormGroup
      label="Calle"
      :name="`${path}.street`"
      :required="isRequired(schema, `${path}.street`)"
    >
      <InputText
        v-model.defaultcase="model.street"
        type="text"
        :disabled="[`${path}.street`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Exterior"
      :name="`${path}.numberExterior`"
      :required="isRequired(schema, `${path}.numberExterior`)"
    >
      <InputText
        v-model.defaultcase="model.numberExterior"
        type="text"
        :disabled="[`${path}.numberExterior`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Interior"
      :name="`${path}.numberInterior`"
      :required="isRequired(schema, `${path}.numberInterior`)"
    >
      <InputText
        v-model.defaultcase="model.numberInterior"
        type="text"
        :disabled="[`${path}.numberInterior`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Colonia"
      :name="`${path}.neighborhood`"
      :required="isRequired(schema, `${path}.neighborhood`)"
    >
      <InputText
        v-model.defaultcase="model.neighborhood"
        type="text"
        :disabled="[`${path}.neighborhood`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Ciudad"
      :name="`${path}.city`"
      :required="isRequired(schema, `${path}.city`)"
    >
      <InputText
        v-model.defaultcase="model.city"
        type="text"
        :disabled="[`${path}.city`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Delegación/Municipio"
      :name="`${path}.district`"
      :required="isRequired(schema, `${path}.district`)"
    >
      <InputText
        v-model.defaultcase="model.district"
        type="text"
        :disabled="[`${path}.district`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Estado"
      :name="`${path}.state`"
      :required="isRequired(schema, `${path}.state`)"
    >
      <InputText
        v-model.defaultcase="model.state"
        type="text"
        :disabled="[`${path}.state`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="País"
      :name="`${path}.country`"
      :required="isRequired(schema, `${path}.country`)"
    >
      <SelectMenuBase
        v-model="model.country"
        :options="optionsShared.countryCode"
        value-attribute="label"
        label-attribute="label"
        :disabled="[`${path}.country`].includes(disabledFields)"
      />
    </UFormGroup>

    <UFormGroup
      label="Código Postal"
      :name="`${path}.postalCode`"
      :required="isRequired(schema, `${path}.postalCode`)"
    >
      <InputText
        v-model.defaultcase="model.postalCode"
        type="text"
        :disabled="[`${path}.postalCode`].includes(disabledFields)"
      />
    </UFormGroup>

    <UButton
      label="Ver en Google Maps"
      variant="outline"
      icon="i-mdi-google-maps"
      :disabled="!model.street || !model.numberExterior || !model.city || !model.state || !model.country"
      :to="`https://www.google.com/maps?q=${encodeURIComponent(model)}`"
      target="_blank"
      class="w-full mt-auto justify-center"
    />
  </fieldset>
</template>
