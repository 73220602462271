<script setup lang="ts">
const model = defineModel<Productionorderitem>({ required: true })

const props = defineProps<{
  utils: ProductionorderitemsUtils
}>()
</script>

<template>
  <details open>
    <summary class="mt-4 mb-2 cursor-pointer font-bold text-gray-700">
      Inspección de calidad
    </summary>
    <fieldset :class="FIELDSET">
      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <div
          v-for="(item, index) in model.data.items"
          :key="`item-${index}`"
          class="flex items-center gap-1"
        >
          <UButton
            variant="ghost"
            size="sm"
            :icon="!item.resolved ? 'i-mdi-checkbox-blank-outline' : 'i-mdi-checkbox-marked'"
            :disabled="!['approval'].includes(model.status)"
            @click="() => item.resolved = !item.resolved"
          />
          <span class="flex-1 block font-medium text-gray-700 dark:text-gray-200">
            {{ item.label }}
          </span>
        </div>
      </div>
      <UFormGroup
        label="Aprobado por"
        name="data.approvedBy"
      >
        <InputSignature
          v-model="model.data.approvedBy"
          :disabled="!['approval'].includes(model.status)"
          @blur="() => model.data.approvedAt = new Date().toISOString()"
        />
      </UFormGroup>

      <UFormGroup
        label="Fecha de aprobación"
        name="data.approvedAt"
      >
        <InputDatetime
          v-model:date="model.data.approvedAt"
          :model-value:is-datetime="true"
          :disabled="!model.data.approvedBy || !['approval'].includes(model.status)"
          class="flex-1"
        />
      </UFormGroup>
      <AppAlert
        type="info"
        description="Para poder cambiar los campos control de calidad, es necesario que el item se encuentre en estado de aprobación."
        class="mt-4"
      />
    </fieldset>
  </details>
</template>
